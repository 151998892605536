<template>
  <section class="selection-container">
    <div class="row">
      <div
        class="col-xs-12 col-md-6 col-lg-4"
        v-for="(item, index) in items"
        v-bind:key="index"
      >
        <ProcessAreaSelectionCardOption
          v-bind:item="item"
          v-on:click="onProcessAreaClick(item)"
        ></ProcessAreaSelectionCardOption>
      </div>
    </div>
  </section>
</template>

<script>
import ProcessAreaSelectionCardOption from "@/components/processarea-selection-card-option.vue";
export default {
  name: "ProcessAreaSelectionCardPanel",
  props: {},
  data() {
    return {};
  },
  components: {
    ProcessAreaSelectionCardOption
  },
  computed: {
    items() {
      let lst = this.$store.getters["equipment/filteredList"] || [];
      let processAreas = {};
      for (var i in lst) {
        var item = lst[i].device;
        var cpa = item.process_area;
        if (!(cpa.id in processAreas)) {
          processAreas[cpa.id] = {
            id: cpa.id,
            name: cpa.name,
            title: cpa.description || cpa.name,
            nStations: 0,
            nConnectedStations: 0,
            nAlarmedStations: 0,
            ids: []
          };
        }
        processAreas[cpa.id].nStations += 1;
        processAreas[cpa.id].nConnectedStations += item.is_connected ? 1 : 0;
        processAreas[cpa.id].nAlarmedStations += item.has_active_alarms ? 1 : 0;
        processAreas[cpa.id].ids.push(item.id);
      }
      let items = null;
      if ("values" in Object) {
        items = Object.values(processAreas);
      } else {
        items = []; // safari/edge not compatible
        for (var j in processAreas) {
          items.push(processAreas[j]);
        }
      }
      return items;
    },
    query: {
      set(value) {
        return this.$store.dispatch("equipment/setQuery", value);
      },
      get() {
        return this.$store.getters["equipment/query"];
      }
    },
    processAreaChecked: {
      set(value) {
        let query = JSON.parse(JSON.stringify(this.query));
        query.query_process_area = query.query_process_area || {};
        query.query_process_area.id = value;
        this.query = query;
      },
      get() {
        return this.query?.query_process_area?.id || "";
      }
    }
  },
  methods: {
    onProcessAreaClick(item) {
      this.processAreaChecked = item.id;
      this.$router.push("/dashboard/search/table");
    }
  }
};
</script>

<style scoped>
.selection-container {
  padding: 20px 5px;
  background-color: whitesmoke;
}

@media (min-width: 720px) {
  .selection-container {
    padding: 30px;
  }
}
</style>
